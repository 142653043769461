
import * as Components from "./Tierra"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "tierra"
}

